<template>
  <div class="card mb-3 shadow-sm sticky-md-top">
    <div class="card-body">
      <!-- Tab Navigation -->
      <ul
        class="nav nav-pills align-items-center"
        id="pills-tab"
        role="tablist"
      >
        <!-- Marketplace -->
        <div class="col">
          <h5 class="m-0">
            <i class="far fa-copyright"></i>
            {{
              this.$route.params.id != undefined
                ? $t("editModel")
                : $t("addNewModel")
            }}
          </h5>
        </div>
        <!-- ./Marketplace -->

        <!-- Submit Button -->
        <li class="nav-item ml-md-auto mx-auto mx-md-0">
          <div class="row align-items-center">
            <div class="col-auto p-0">
              <WeLanguageSwitcher v-model="model.lang" />
            </div>
            <div class="col pl-0">
              <WeSubmitButton
                parent-class="text-center"
                v-bind:submit-status="submit"
                v-bind:update="$route.params.id"
                v-bind:permission="checkPermission"
                v-on:submit="saveForm"
              />
            </div>
          </div>
        </li>
        <!-- ./Submit Button -->
      </ul>
      <!-- Tab Navigation -->
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  name: "Navigation",
  props: {
    submit: {
      default: false,
    },
    errors: {
      default: () => [],
    },
  },
  methods: {
    saveForm() {
      this.$emit("save-form");
    },
    checkError(type) {
      return this.errors.includes(type);
    },
  },
  computed: {
    checkVariant() {
      let selectedProductType = this.productDetail.product_type;
      if (selectedProductType) {
        let checkIndex = this.productTypes.findIndex(
          (item) => item.id === selectedProductType
        );
        if (checkIndex < 0 || checkIndex == 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    checkPermission() {
      return permission.check("model", "u");
    },
    ...mapState(["model"]),
    marketplaceIntegration() {
      return (
        this.config["site.marketplace_integration"] == 1 ||
        this.config["site.marketplace_integration"] == "1"
      );
    },
  },
  watch: {
    "model.lang": {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$emit("change-language", newVal);
        }
      },
    },
  },
};
</script>
